<template>
  <div style="padding-bottom: 60px">
    <div style="border-bottom: 1px solid #ccc; padding: 10px">
      <el-select v-model="csValue" placeholder="请选择" size="mini" @change="handleChangeCs">
        <el-option v-for="item in csOptions" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
        </el-option>
      </el-select>
      <el-select v-model="queryData.order_status" placeholder="上传类型" size="mini" clearable style="width: 150px; margin-left: 10px">
        <el-option v-for="(item, index) in options" :label="item.label" :value="item.value" :key="index">
          {{ item.label }}
        </el-option>
      </el-select>
      <el-input v-model="queryData.order_sn" placeholder="请输入订单" size="mini" style="width: 150px; margin-left: 10px">
      </el-input>
      <el-input v-model="queryData.hd_id" placeholder="门店编码" size="mini" style="width: 150px; margin-left: 10px">
      </el-input>
      <!--      <el-input v-show="1 == 2" v-model="queryData.cs_id" placeholder="客服" size="mini"
                style="width: 150px; margin-left: 10px">
      </el-input>-->
      <el-button size="mini" type="primary" style="margin-left: 10px" @click="searchOrder">搜索</el-button>
    </div>

    <div style="padding: 10px">
      <el-button size="mini" @click="uploadOrder2Haiding" :loading="uploading">上传</el-button>
      <el-button size="mini" @click="importOrderThePages" style="background: #407ee7; color: white; border: none">
        本页订单合并
      </el-button>
      <el-button size="mini" @click="importOrder.importShow = true" v-show="importOrder.importShow === false" style="background: #01a3a3; color: white; border: none">
        跨页订单合并
      </el-button>

      <el-button size="mini" type="primary" @click="showExportOrderData = true" v-if="showExportOrderData === false">
        导出订单
      </el-button>
      <el-button size="mini" type="danger" @click="setNewOrder(1)">设为新单</el-button>
      <el-button size="mini" type="info" @click="setNewOrder(0)">设为补单</el-button>
      <el-button size="mini" type="warning" @click="createOrdersText">生成订单商品信息</el-button>
      <el-button size="mini" type="danger" @click="handleCorr">库存补正</el-button>
      <!--      <el-button size="mini" type="danger" @click="handleDelete">删除</el-button>-->
    </div>

    <div class="exportOrderDiv" v-show="showExportOrderData === true">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>设置导出参数</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="showExportOrderData = false">
            关闭
          </el-button>
        </div>
        <div>
          选择语言：
          <el-radio v-model="exportOrderLg" label="cn">中文</el-radio>
          <el-radio v-model="exportOrderLg" label="en">英文</el-radio>
          <el-radio v-model="exportOrderLg" label="all">中英文</el-radio>
          &nbsp;
          <el-button size="mini" type="primary" @click="downMulOrder">导出</el-button>
          <el-button size="mini" type="infor" @click="showExportOrderData = false">关闭</el-button>
        </div>
      </el-card>
    </div>

    <div class="importOrder" v-show="importOrder.importShow === true">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>合并订单</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="importOrder.importShow = false">
            关闭
          </el-button>
        </div>
        <div>
          <el-form label-width="80px">
            <el-form-item label="主订单">
              <!-- <el-select v-model="importOrder.baseOrder" filterable placeholder="请选择">
                <el-option v-for="item in tableData" :key="item.order_sn" :label="item.order_sn"
                  :value="item">
                </el-option>
              </el-select> -->
              <el-input v-model="importOrder.baseOrder" placeholder="请输入主订单" style="width: 300px">
              </el-input>
            </el-form-item>

            <el-form-item label="合成订单">
              <textarea v-model="importOrder.value" style="
                  border: 1px solid #ccc;
                  width: 300px;
                  font-size: 12px;
                  line-height: 15px;">
              </textarea>
              <br />
              示例：
              <p style="color: #38c0ff">
                163711449xxxxxxx <br />
                163711448xxxxxxx
              </p>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="mini" @click="importOrderFun">合并订单</el-button>
              <el-button size="mini" @click="importOrder.importShow = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
    <div style="margin-left: 10px">
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" border>
        <el-table-column type="selection" width="55" align="center"></el-table-column>

        <el-table-column prop="hd_id" label="门店编码" width="100" align="center"></el-table-column>

        <el-table-column prop="hd_name" label="门店名称" width="120" align="center"></el-table-column>

        <el-table-column prop="order_sn" label="订单号" width="200" align="center"></el-table-column>

        <el-table-column prop="order_status" label="状态" width="120" align="center">
          <template v-slot="scope">
            <el-tag v-if="scope.row.order_status === 0" type="success">待传输</el-tag>
            <el-tag v-if="scope.row.order_status === 1" type="danger">已传输</el-tag>
            <el-tag v-if="scope.row.clean_status === 1" type="danger" style="margin-left: 10px" @click="showCleanInfo(scope.row)">
              清
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="order_amount" label="金额" width="120" align="center">
          <template slot="header">
            金额<br />
            <span style="color: red">总额：￥{{ thePageOrderTotal | toFixed2 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单时间" align="center" width="150">
          <template v-slot="scope">
            {{ scope.row.created_at | formatDateStr }}
          </template>
        </el-table-column>

        <el-table-column prop="user_name" label="客服" width="100" align="center">
        </el-table-column>

        <el-table-column prop="is_new" label="订单类型" align="center" width="80">
          <template v-slot="scope">
            <el-tag type="success" v-if="scope.row.is_new === '1'">新单</el-tag>
            <el-tag type="warning" v-if="scope.row.is_new === '0'">补货</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="order_pack_type_id" label="生成方式" align="center" width="100">
          <template v-slot="scope">
            <el-tag type="success" v-if="scope.row.order_pack_type === 0">小类订单</el-tag>
            <el-tag type="danger" v-if="scope.row.order_pack_type === 1">中类订单</el-tag>
            <el-tag type="warning" v-if="scope.row.order_pack_type === 2">大类订单</el-tag>
            <br />
            <!-- <el-tag style="margin-top: 5px;" type="info">{{scope.row.order_pack_type_id}}</el-tag> -->
            <el-tag style="margin-top: 5px" type="info">{{ scope.row.order_pack_type_id_text }}</el-tag>
            <el-tag style="margin-top: 5px;">{{ scope.row.seriesid > 0 ? series[scope.row.seriesid] : '无系列'}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="备注" width="200" align="center">
          <template v-slot="scope">
            <el-input v-model="scope.row.remark" placeholder="请填写备注" @change="upRemark(scope.row)" size="mini">
            </el-input>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="操作" align="left" width="320">
          <template v-slot="scope">
            <el-tooltip class="item" effect="dark" content="生成文本框商品信息" placement="top">
              <el-button size="mini" icon="el-icon-setting" type="infor" @click="getOrderGoodsText(scope.row)"></el-button>
            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="导出订单商品信息" placement="top">
              <el-button size="mini" icon="el-icon-download" type="primary" @click="exportOrderKey(scope.row)"></el-button>
            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="上传到海鼎系统" placement="top" v-if="scope.row.order_status === 0">
              <el-button size="mini" icon="el-icon-upload" type="danger" @click="submit2hd(scope.row)" :loading="uploading">
              </el-button>
            </el-tooltip>
            <el-button size="mini" icon="el-icon-s-unfold" type="warning" @click="showOrderIifo(scope.row)">
              详情
            </el-button>

            <el-tooltip class="item" effect="dark" content="删除订单" placement="top" v-if="scope.row.order_status === 0">
              <el-button size="mini" @click="delOrder(scope.row.id)" icon="el-icon-delete-solid" type="info"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div ref="footer" class="footer">
        <el-pagination :key="reloadPaginate" :current-page="pagination.page" :page-size="pagination.page_size" :page-sizes="[10, 20, 50, 100, 200]" layout="total,sizes, prev, pager, next, jumper" :total="pagination.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
      </div>
    </div>
    <!-- 抽屉：展示订单商品列表 -->
    <el-drawer title="订单详情" :visible.sync="orderGoods.drawer" :direction="orderGoods.direction" size="80%">
      <div class="order-info">
        <div class="header-info">
          <div class="item-wrap">
            <span>订单单号: {{ orderGoods.orderInfo.order_sn }}</span>
            <span class="ordertime-wrap">下单时间: {{ orderGoods.orderInfo.created_at | formatDateStr }}</span>
          </div>
          <div class="total-wrap">
            订单金额:{{ orderGoods.orderInfo.order_amount }}
          </div>
        </div>
        <div class="button-wrap">
          <el-button class="download" type="danger" style="margin-bottom: 10px" @click="dowCGoodsFile">认证文件下载
          </el-button>
          <el-upload class="upload" :action="this.$root.DownUrl+'/a1/order/goods/check'" ref="upload" :on-success="handleChange" :auto-upload="true" name="file" :limit=1 :data="updateData" :http-request="uploadHdGoods" :file-list="fileList">
            <el-button size="small" type="success">选取海鼎商品列表</el-button>
            <div slot="tip" class="el-upload__tip">只能上传csv/xlsx文件，且不超过500kb</div>
          </el-upload>
        </div>
        <div style="
            border: 1px solid skyblue;
            height: 30px;
            line-height: 30px;
            background: skyblue;
            color: white;
            text-indent: 1em;
          ">
          商品列表
        </div>
        <el-table :data="orderGoods.orderGoodsTable" border style="width: 100%" height="650" @selection-change="selectionGoodsChange">
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column fixed prop="item_name" label="商品名称" width="220" align="center"></el-table-column>
          <el-table-column prop="goods_item" label="SKU" width="120" align="center"></el-table-column>

          <el-table-column label="图片" width="150" align="center">
            <template slot-scope="scope">
              <img height="60" :src="`http://hwimg.xmvogue.com/thumb/${scope.row.goods_item}.jpg?x-oss-process=style/440`" class="mr-3" alt="..." />
            </template>
          </el-table-column>

          <el-table-column label="认证" width="150" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.has_cre === 0">无认证</span>
              <span v-if="scope.row.has_cre === 1">
                <img :key="findex" v-for="(fitem, findex) in scope.row.file_type_icon_list_arr" width="30" :src="`${fitem}`" class="mr-3" alt="..." />
              </span>
            </template>
          </el-table-column>

          <el-table-column label="数量" align="center" width="220">
            <template v-slot="scope">
              <el-input-number v-model="scope.row.goods_number" :step="scope.row.spe" placeholder="请输入产品数量" size="mini" @change="(currentValue, oldValue) => updateGoods(currentValue, oldValue, scope.row)">
              </el-input-number>
            </template>
          </el-table-column>

          <el-table-column prop="goods_price" label="单价" width="120" align="center"></el-table-column>
          <el-table-column prop="sell_price" label="产品原价" align="center"></el-table-column>
          <el-table-column prop="spe" label="规格" width="120" align="center"></el-table-column>
          <el-table-column prop="customs" label="报关" align="center"></el-table-column>
          <el-table-column prop="goods_total" label="总价" width="120" align="center"></el-table-column>

          <el-table-column fixed="right" label="操作" align="center" v-if="orderGoods.orderInfo.order_status === 0">
            <template v-slot="scope">
              <el-button type="danger" size="mini" @click="delOrderGoods(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <el-dialog title="订单商品信息" :visible.sync="showTextDialog" width="30%">
      <textarea class="showOrderGoodsText" :value="showOrderGoodsText"></textarea>
    </el-dialog>

    <el-dialog title="订单商品清除记录" :visible.sync="showCleanLogWrap" height="850" width="60%">
      <el-table ref="multipleCleanTable" :data="cleanLogData" tooltip-effect="dark" style="width: 100%" max-height="450" @selection-change="handleSelectionChange" border>
        <el-table-column prop="itemNo" label="商品编码" width="120" align="center"></el-table-column>
        <el-table-column prop="preNumber" label="变更前数量" width="120" align="center"></el-table-column>
        <el-table-column prop="changeNumber" label="变更数量" width="120" align="center"></el-table-column>
        <el-table-column prop="changeType" label="变更类型" align="center">
          <template v-slot="scope">
            {{ scope.row.changeType === 1 ? "移除" : "补正"}}
          </template>
        </el-table-column>
        <el-table-column prop="description" label="变更描述" width="200" align="center"></el-table-column>
        <el-table-column prop="createTime" label="变更时间" width="200" align="center">
          <template v-slot="scope">
            {{ new Date(scope.row.createTime) * 1 /1000 | formatDateStr}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="scope">
            <el-button v-if="scope.row.changeType === 1 && scope.row.patchNumber !== scope.row.changeNumber " @click="findCleanOrderGoods(scope.row)">找回</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import orderList from "./js/index";

export default orderList;
</script>

<style scoped lang="scss">
@import "./css/index";
</style>
