import axios from "axios";

export default {
  getCleanLogList: (orderid) => {
    return axios.get("/a1/order/orderclean", { params: { id: orderid } });
  },
  findCleanOrderGoods(params) {
    return axios.post("/a1/order/orderclean/find", params);
  },
};
