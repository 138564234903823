import common from "@/common/mixins/common.js";
import orderApi from "@/api/admin/order.js";


export default {
  mixins: [common],
  data() {
    return {
      thePageOrderTotal: 0, //当前页面订单总额
      value1: [],
      importOrder: {
        importShow: false,
        data: [],
        value: [],
        baseOrder: "",
      },
      value: "",
      input: "",
      options: [{
        value: "0",
        label: "未上传",
      },
        {
          value: "1",
          label: "已上传",
        },
      ],
      //导出订单数据
      showExportOrderData: false,
      tableData: [],
      //订单选择
      multipleSelection: [],
      queryData: {
        //查询得数据
        page: 1,
        page_size: 10,
        cs_id: 0,
        user_id: 0,
        order_status: "0",
      },
      pagination: {
        total: 0,
        page: 1,
        page_size: 10,
      },
      reloadPaginate: 0,
      orderGoods: {
        orderInfo: {},
        drawer: false,
        direction: "rtl",
        orderGoodsTable: [],
      },
      showOrderGoodsText: "",
      showTextDialog: false,
      goodsType: [], //商品类型
      multipleGoods: [], //选项商品选项
      exportOrderLg: "cn", //导出订单语种选择,
      allUserList: [],
      csOptions: [
        {
          value: "10",
          label: "何虹均",
          disabled: true,
        },
        {
          value: "4",
          label: "戴冰冰",
          disabled: true,
        },
        {
          value: "5",
          label: "刘春燕",
          disabled: true,
        },
        {
          value: "7",
          label: "唐珊",
          disabled: true,
        },
        {
          value: "8",
          label: "冯韫琪",
          disabled: true,
        },
        {
          value: "20",
          label: "林莹",
          disabled: true,
        },
      ],
      csValue: "",
      csid: 0,
      uploading: false,
      fileList: [],
      updateData: {},
      showCleanLogWrap: false,
      cleanLogData: [],
      series: [],
    };
  },
  created() {
    this.initCsOption();
    this.getData();
    // this.getGoodsType();//改在后端返回数据，防止数据量大，卡顿
    this.getSeries();
  },
  methods: {
    //批量上传海鼎系统
    uploadOrder2Haiding() {
      if (this.multipleSelection.length < 1) {
        this.$message.error("请选择订单");
      } else {
        this.$confirm("确定把选中的订单提交到海鼎？").then(() => {
          this.uploading=true;
          let OrderArr=[];
          for (let i=0; i < this.multipleSelection.length; i++) {
            let row=this.multipleSelection[i];
            OrderArr.push(row.order_sn);
          }
          let postData={
            orderSn: OrderArr,
          };
          this.$axios
            .post("/a1/order/submitOrder2hd", postData)
            .then(() => {
              this.$message.success("上传海鼎成功");
              this.getData();
              this.uploading=false;
            })
            .catch(() => {
              this.$message.error("");
            });
        });
      }
    },
    //单个上传到海鼎系统
    submit2hd(row) {

      this.$confirm("确定提交到海鼎").then(() => {
        this.uploading=true;
        let postData={
          orderSn: [row.order_sn],
        };
        this.$axios
          .post("/a1/order/submitOrder2hd", postData)
          .then(() => {
            this.$message.success("上传海鼎成功");
            this.getData();
            this.uploading=false;
          })
          .catch(() => {
            this.$message.error("");
          });
      });
    },
    //当前页合并
    importOrderThePages() {
      if (this.multipleSelection.length < 2) {
        this.$message.success("请选择2条以上要合并的订单");
      } else {
        //订单选项

        let firstOrderSn="";
        let importOrderSnArr=[];
        let maxTime=this.multipleSelection[0].created_at, key=0;
        for (let i=0; i < this.multipleSelection.length; i++) {

          if (this.multipleSelection[i + 1]) {
            if (this.multipleSelection[i + 1].created_at > maxTime) {
              key=i + 1;
            }
            maxTime=maxTime < this.multipleSelection[i + 1].created_at ? this.multipleSelection[i + 1].created_at : maxTime;
          }

          /*if (i === key) {
            firstOrderSn = this.multipleSelection[i].order_sn;
          } else {
            importOrderSnArr.push(this.multipleSelection[i].order_sn);
          }*/
        }

        //目标sn 为key 切出key 数组剩余为合并子项
        firstOrderSn=this.multipleSelection[key].order_sn;
        this.multipleSelection.splice(key, 1);

        this.multipleSelection.forEach(item => {
          importOrderSnArr.push(item.order_sn);
        });

        let postData={
          base_order: firstOrderSn,
          im_order: importOrderSnArr,
        };

        let loadingInstance1=this.$loading({ fullscreen: true, text: "数据正在提交" });

        this.$axios.post("/a1/order/import", postData).then(() => {
          this.$message.success("订单合并成功");
          this.importOrder.baseOrder="";
          this.importOrder.value="";
          this.getData();
          this.importOrder.importShow=false;
          loadingInstance1.close();
        });
      }
    },
    //跨页合并订单
    importOrderFun() {
      if (this.importOrder.baseOrder && this.importOrder.value !== "") {
        let valueList=this.importOrder.value.split("\n");
        let postData={
          base_order: this.importOrder.baseOrder,
          im_order: valueList,
        };
        this.$axios.post("/a1/order/import", postData).then(() => {
          this.$message.success("订单合并成功");
          this.importOrder.baseOrder="";
          this.importOrder.value="";
          this.getData();
          this.importOrder.importShow=false;
        });
      } else {
        this.$message.warning("请选主订单及合成订单");
      }
    },
    //选择商品选择
    selectionGoodsChange(val) {
      this.multipleGoods=val;
    },
    //删除订单
    delOrder(id) {
      this.$confirm("确认删除吗？")
        .then(() => {
          this.$axios.delete("/a1/order/" + id).then(() => {
            this.getData();
          });
        })
        .catch(() => {
        });
    },
    //获取商品类别
    // getGoodsType() {
    //   this.$axios.get("/a1/gt").then((res) => {
    //     let newGoodsType=[];
    //     let goodsType=res.data.data;
    //     for (let i=0; i < goodsType.length; i++) {
    //       newGoodsType[goodsType[i].cls_no]=goodsType[i].cls_cn;
    //     }
    //     this.goodsType=newGoodsType;
    //   });
    // },
    //设置新单
    setNewOrder(value) {
      let postIds=[];
      if (this.multipleSelection.length < 1) {
        this.$message.error("请选择需要设置为新单的数据");
        return;
      }
      for (let i=0; i < this.multipleSelection.length; i++) {
        postIds.push(this.multipleSelection[i].id);
      }
      let postData={
        value: parseInt(value),
        ids: postIds,
      };
      this.$axios
        .put("/a1/order/setNewOrder", postData)
        .then(() => {
          this.$message.success("设置成功过");
          this.getData();
        })
        .catch(() => {
          this.$message.error("设置失败");
        });
    },

    //下载商品对应的认证文件
    dowCGoodsFile() {
      if (this.multipleGoods.length < 1) {
        this.$message.error("请选择需要导出的商品");
      } else {
        let goodsData=[];
        for (let i=0; i < this.multipleGoods.length; i++) {
          var orderGoods=this.multipleGoods[i];
          if (orderGoods.has_cre === 1) {
            goodsData.push(orderGoods.goods_item);
          }
        }
        //生成download
        let postData={
          goods: goodsData,
        };
        this.$axios.post("/a1/order/downCredential", postData).then((res) => {
          let dowloadKey=res.data.data.to_key;
          //开始下载
          location.href=this.$root.DownUrl + "/c1/down/" + dowloadKey;
        });
      }
    },
    //查询订单
    searchOrder() {
      this.queryData.page=1;
      this.getData();
    },
    //下载多个订单
    downMulOrder() {
      if (this.multipleSelection.length < 1) {
        this.$message.error("请选择需要下载的订单");
        return;
      }
      let postIds=[];
      for (let i=0; i < this.multipleSelection.length; i++) {
        postIds.push(this.multipleSelection[i].id);
      }
      this.createOrderExcelKey(postIds);
    },
    //导出单个订单key
    exportOrderKey(row) {
      this.createOrderExcelKey([row.id]);
    },
    //生成订单下载
    createOrderExcelKey(postIds) {
      let postData={
        lg: this.exportOrderLg, //cn
        order_ids: postIds,
      };
      this.$axios
        .post("/a1/order/createOrderExcelKey", postData)
        .then((res) => {
          let resData=res.data.data;
          window.location.href=
            this.$root.DownUrl + "/c1/down/" + resData.to_key;
        });
    },
    //生成[当前]订单商品信息
    getOrderGoodsText(row) {
      this.postOrderTextShow([row.id]);
    },
    //生成[多个]订单商品信息
    createOrdersText() {
      let goodsData=[];
      for (let i=0; i < this.multipleSelection.length; i++) {
        goodsData.push(this.multipleSelection[i].id);
      }
      this.postOrderTextShow(goodsData);
    },
    //显示订单商品信息
    postOrderTextShow(goodsData) {
      let showText="";
      let postData={
        order_ids: goodsData,
      };
      this.$axios.post("/a1/order/goods/text", postData).then((res) => {
        let resData=res.data.data;
        for (let i=0; i < resData.length; i++) {
          showText+=resData[i] + "\r";
        }
        this.showOrderGoodsText=showText;
        this.showTextDialog=true;
      });
    },
    // 分页跳转
    handleSizeChange(val) {
      this.queryData.page_size=val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.queryData.page=val;
      this.getData();
    },
    //修改商品
    updateGoods(currentValue, oldValue, rowData) {
      if (currentValue % rowData.spe !== 0) {
        this.$message.error("请按规格倍数更改，当前商品规格为:" + rowData.spe);
        //上下文绑定
        this.$nextTick(() => {
          rowData.goods_number=oldValue;
        });
      } else {
        //修改之前需要判断是否已经超出了海鼎库存了
        this.$axios.get("/a1/goods/" + rowData.goods_item).then((res) => {
          let resData=res.data.data;
          let goodsInfo=resData.GoodsInfo;
          if (goodsInfo.us_num > rowData.goods_number) {
            this.$axios
              .put(`/a1/order/UpGoods/${rowData.id}/${rowData.goods_number}`)
              .then(() => {
                this.getData();
                this.showOrderIifo(this.orderGoods.orderInfo);
                this.$message.success("修改成功");
              });
          } else {
            this.$message.error("已经超出库存了");
          }
        });

      }
    },
    //删除订单商品
    delOrderGoods(row) {
      let orderId=this.orderGoods.orderInfo.id;
      let goodItem=row.goods_item;
      if (orderId && goodItem) {
        this.$axios
          .delete(`/a1/order/delGoods/${orderId}/${goodItem}`)
          .then((res) => {
            this.orderGoods.orderInfo=res.data.data;
            this.$message.success("删除成功");
            this.getData();
            this.showOrderIifo(this.orderGoods.orderInfo);
          });
      } else {
        this.$message.error("参数有误");
      }
    },
    //显示订单详情
    showOrderIifo(row) {
      this.orderGoods.orderInfo=row;
      this.$axios.get("/a1/order/getGoods/" + row.id).then((res) => {
        this.orderGoods.drawer=true;

        let goodsList=res.data.data;
        if (goodsList.length > 0) {
          for (let i=0; i < goodsList.length; i++) {
            goodsList[i].file_type_icon_list_arr=
              goodsList[i].file_type_icon_list.split(",");
          }
        }
        this.orderGoods.orderGoodsTable=goodsList;
      });
    },
    //修改备注
    upRemark(row) {
      this.$axios
        .put("/a1/order/remark/" + row.id, {
          remark: row.remark,
        })
        .then(() => {
          this.$message.success("备注修改成功");
        });
    },
    //获取到数据列表
    getData() {
      let adminUser=JSON.parse(window.sessionStorage.getItem("user"));
      if (adminUser.admin_info.user !== "admin") {
        this.queryData.cs_id=adminUser.admin_info.id;
      }

      if (this.csid) this.queryData.cs_id=this.csid;

      this.thePageOrderTotal=0;

      this.$axios
        .get("/a1/order", {
          params: this.queryData,
        })
        .then((res) => {
          const {
            data,
          }=res.data;

          // 重新设置翻页数据 beign
          this.pagination.total=data.total;
          this.pagination.page=data.page;
          this.pagination.page_size=data.page_size;
          // 重新设置翻页数据 end
          this.tableData=data.data;

          var tmp=0;
          for (var i in data.data) {
            tmp+=(data.data[i]["order_amount"] * 1000);
          }
          this.thePageOrderTotal=(tmp / 1000);

          this.reloadPaginate++;
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection=val;
    },
    initCsOption() {

      const adminInfo=JSON.parse(sessionStorage.getItem("user"));
      if (!adminInfo || !([2, 4, 5, 6, 7, 8, 10, 20, 38].includes(adminInfo.admin_info.id))) {
        this.csOptions=[];
      }

      this.csValue=adminInfo.admin_info.user_name;
      this.csid=adminInfo.admin_info.id;

      this.csOptions.forEach(item => {
        if (item.label === adminInfo.admin_info.user_name) item.disabled=false;
      });

      switch (adminInfo.admin_info.id) {
        case 2:
        case 10:
          this.csOptions.forEach(item => {
            item.disabled=false;
          });
          break;
        case 4:
          this.csOptions.forEach(item => {
            if (item.label === "刘春燕") item.disabled=false;
          });
          break;
        case 7:
          this.csOptions.forEach(item => {
            if (item.label === "冯韫琪" || item.label === "林莹") item.disabled=false;
          });
          break;
      }
    },
    handleChangeCs() {
      this.csid=this.csValue;
      this.queryData.page=1;
      this.getData();
    },
    //批量删除订单
    handleDelete() {

      let poseList=[];

      this.multipleSelection.forEach(item => {
        poseList.push(this.$axios.delete("/a1/order/" + item.id));
      });
    },
    handleCorr() {
      this.$axios.put("/a1/goods/corr");
    },
    handleChange() {
    },
    uploadHdGoods(params) {
      let postData=new FormData;
      postData.append("file", params.file);
      postData.append("orderid", this.orderGoods.orderInfo.id);

      this.$axios.post("/a1/order/goods/check", postData).then(res => {
        this.$message.success("正在校正订单数据，请稍后");
        this.fileList=[];

      }).catch(err => {

        const { data }=err.response;

        this.$message.error(data.message);

        this.fileList=[];

      });
    },
    showCleanInfo(row) {
      this.showCleanLogWrap=true;
      this.orderid=row.id;
      orderApi.getCleanLogList(this.orderid).then(res => {
        this.cleanLogData=res.data.data;
      });
    },
    findCleanOrderGoods(row) {
      orderApi.findCleanOrderGoods({
        id: row.id,
        orderid: row.orderid,
        itemNo: row.itemNo,
      }).then(() => {
        orderApi.getCleanLogList(this.orderid).then(res => {
          this.cleanLogData=res.data.data;
          this.getData();
        });
      });
    },
    getSeries() {

      this.$axios.get("/a1/series").then(res => {

        const { data }=res.data;

        let t=[];

        data.forEach(item => {
          t[item.id]=item.name;
        });

        this.series=t;

      });

    },
  },
};
